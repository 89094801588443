<template>
  <div id="user-edit-tab-info">
    <!-- Content Row -->
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">

        <vs-input class="w-full mt-4" label="Nombre" v-model="data_local.name" v-validate="'required|alpha_spaces'" name="name" autofocus />
        <span class="text-danger text-sm"  v-show="errors.has('name')">{{ errors.first('name') }}</span>

        <vs-input class="w-full mt-4" label="Apellido" v-model="data_local.last_name" v-validate="'required|alpha_spaces'" name="last_name" />
        <span class="text-danger text-sm"  v-show="errors.has('last_name')">{{ errors.first('last_name') }}</span>

        <div class="mt-4 flex flex-wrap">
          <div class="w-1/2">
            <vs-input class="w-full" label="Email" v-model="data_local.email" type="email" v-validate="'required|email'" name="email" />
            <span class="text-danger text-sm"  v-show="errors.has('email')">{{ errors.first('email') }}</span>
          </div>
          <div class="w-1/2">
            <vs-input label="Teléfono" class="w-full pl-4" v-model="data_local.phone_number" name="phone_number" placeholder="Número de teléfono"/>
            <span class="text-danger text-sm"  v-show="errors.has('phone_number')">{{ errors.first('phone_number') }}</span>
          </div>
        </div>

        <div class="mt-4 flex flex-wrap">
          <label class="vs-input--label w-full">Documento de Identidad</label>
          <div class="w-1/2">
          <!-- @input="updateCardType($event)" -->
            <!-- <vs-select :clearable="false"  v-validate="'required'" v-model="data_local.card_type_id" name="card_type_id" placeholder="Tipo" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <vs-select-item :key="item.value" :value="item.value" :text="item.text" v-for="item in cardTypeOptions" />
            </vs-select> -->
            <v-select browserAutocomplete="off" autocomplete="off" v-model="data_local.card_type_id" :reduce="card_type_id => card_type_id.value" :options="cardTypeOptions" :clearable="false" v-validate="'required'" name="card_type_id" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
            <span class="text-danger text-sm"  v-show="errors.has('card_type_id')">{{ errors.first('card_type_id') }}</span>
          </div>
          <div class="w-1/2 pl-4">
            <vs-input class="w-full" v-model="data_local.card_id" v-validate="'required|max:15'" name="card_id" placeholder="Número"/>
            <span class="text-danger text-sm"  v-show="errors.has('card_id')">{{ errors.first('card_id') }}</span>
          </div>
        </div>
      </div>

      <div class="vx-col md:w-1/2 w-full">
        <div class="mt-4">
          <label>Fecha de Nacimiento</label>
          <div class="flex flex-wrap">
            <datepicker :disabledDates="disabledDates" :openDate="openDate" :format="dateFormat" v-model="data_local.birthday_date" v-validate="'required'" name="birthday_date"></datepicker>
            <span class="text-danger text-sm"  v-show="errors.has('birthday_date')">{{ errors.first('birthday_date') }}</span>
          </div>
        </div>

        <div class="mt-4 flex flex-wrap">
          <vs-input class="w-1/2" label="Dirección" v-model="data_local.address" name="address" />
          <span class="text-danger text-sm"  v-show="errors.has('address')">{{ errors.first('address') }}</span>
          <vs-input class="w-1/4 pl-4" label="Ciudad" v-model="data_local.city" name="city" placeholder="Ciudad"/>
          <span class="text-danger text-sm"  v-show="errors.has('city')">{{ errors.first('city') }}</span>
          <vs-input class="w-1/4 pl-4" label="Código Postal" v-model="data_local.zip_code" name="zip_code" placeholder="Código Postal"/>
          <span class="text-danger text-sm"  v-show="errors.has('zip_code')">{{ errors.first('zip_code') }}</span>
        </div>

        <div class="mt-4">
          <label class="vs-input--label">Rol</label>
          <v-select browserAutocomplete="off" autocomplete="off" v-model="data_local.profile_type" :reduce="profile_type => profile_type.value" :options="roleOptions" :clearable="false" v-validate="'required'" name="profile_type" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
          <!-- <vs-select :clearable="false" v-model="data_local.profile_type" :options="roleOptions" placeholder="Rol" v-validate="'required'" name="profile_type" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <vs-select-item :key="item.value" :value="item.value" :text="item.text" v-for="item in roleOptions" />
          </vs-select> -->
          <span class="text-danger text-sm"  v-show="errors.has('profile_type')">{{ errors.first('profile_type') }}</span>
        </div>

        <!-- <div class="mt-4">
          <label class="vs-input--label">Estado</label>
          <v-select :clearable="false" :options="statusOptions" @input="updateStatus($event)" v-validate="'required'" name="status" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
          <span class="text-danger text-sm"  v-show="errors.has('status')">{{ errors.first('status') }}</span>
        </div> -->

        <!-- <vs-input class="w-full mt-4" label="Company" v-model="data_local.company" v-validate="'alpha_spaces'" name="company" />
        <span class="text-danger text-sm"  v-show="errors.has('company')">{{ errors.first('company') }}</span> -->

      </div>
    </div>
    <!-- Content Row -->
    <!-- <div class="vx-row"> -->
      <!-- <div class="vx-col md:w-1/2 w-full"> -->
        <!-- <vs-input class="w-full mt-4" label="Username" v-model="data_local.username" v-validate="'required|alpha_num'" name="username" />
        <span class="text-danger text-sm"  v-show="errors.has('username')">{{ errors.first('username') }}</span> -->

        <!-- <vs-input class="w-full mt-4" label="Name" v-model="data_local.name" v-validate="'required|alpha_spaces'" name="name" />
        <span class="text-danger text-sm"  v-show="errors.has('name')">{{ errors.first('name') }}</span>

        <vs-input class="w-full mt-4" label="Apellido" v-model="data_local.last_name" v-validate="'required|alpha_spaces'" name="last_name" />
        <span class="text-danger text-sm"  v-show="errors.has('last_name')">{{ errors.first('last_name') }}</span>

        <vs-input class="w-full mt-4" label="Email" v-model="data_local.email" type="email" v-validate="'required|email'" name="email" />
        <span class="text-danger text-sm"  v-show="errors.has('email')">{{ errors.first('email') }}</span>
      </div>

      <div class="vx-col md:w-1/2 w-full"> -->

        <!-- <div class="mt-4">
          <label class="vs-input--label">Rol</label>
          <v-select v-model="role_local" :clearable="false" :options="roleOptions" v-validate="'required'" name="role" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
          <span class="text-danger text-sm"  v-show="errors.has('role')">{{ errors.first('role') }}</span>
        </div> -->

        <!-- <div class="mt-4">
          <label class="vs-input--label">Status</label>
          <v-select v-model="status_local" :clearable="false" :options="statusOptions" v-validate="'required'" name="status" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
          <span class="text-danger text-sm"  v-show="errors.has('status')">{{ errors.first('status') }}</span>
        </div> -->

        <!-- <vs-input class="w-full mt-4" label="Company" v-model="data_local.company" v-validate="'alpha_spaces'" name="company" />
        <span class="text-danger text-sm"  v-show="errors.has('company')">{{ errors.first('company') }}</span> -->

      <!-- </div> -->
    <!-- </div> -->

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" @click="save_changes" :disabled="!validateForm">Guardar</vs-button>
          <vs-button class="ml-4 mt-2" type="border" color="warning" @click="reset_data">Cancelar</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'

export default {
  components: {
    Datepicker,
    'v-select': vSelect,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {

      data_local: JSON.parse(JSON.stringify(this.data)),

      statusOptions: [
        { label: "Activo",  value: "1" },
        { label: "Inactivo",  value: "0" },
      ],
      bankOptions: [
        { label: "Credicorp Bank Panamá",  value: "1" },
      ],
      cardTypeOptions: [
        { label: "CEDULA", value: 1 },
        { label: "PASAPORTE", value: 2 },
      ],
      roleOptions: [
        { label: "Gerente",  value: 4 },
        { label: "Cajero",  value: 5 },
      ],

      disabledDates: {
        // to: new Date(new Date().getFullYear()-18, new Date().getMonth(), new Date().getDay()), // Disable all dates up to specific date
        from: new Date(new Date().getFullYear()-16, new Date().getMonth(), new Date().getDay()), // Disable all dates after specific date
        // daysOfMonth: [8, 11, 17], // Disable Specific days
      },
      openDate: new Date(new Date().getFullYear()-18, new Date().getMonth(), new Date().getDay()),
      dateFormat: "dd-MM-yyyy",

    }
  },
  computed: {
    status_local: {
      get() {
        return { label: this.capitalize(this.data_local.status),  value: this.data_local.status  }
      },
      set(obj) {
        this.data_local.status = obj.value
      }
    },
    role_local: {
      get() {
        return { label: this.capitalize(this.data_local.role),  value: this.data_local.role  }
      },
      set(obj) {
        this.data_local.role = obj.value
      }
    },
    validateForm() {
      return !this.errors.any() && this.data_local.card_type_id
    }
  },
  methods: {
    toggleProfileType() {
      this.data_local.profile_type = this.data_local.profile_type == 4 ? 5 : 4;
    },
    capitalize(str) {
      return str.slice(0,1).toUpperCase() + str.slice(1,str.length)
    },
    save_changes() {
      if(!this.validateForm) return

      this.data_local.birthday_date = this.isDate(this.data_local.birthday_date) ? this.data_local.birthday_date.toISOString().split("T")[0] : this.data_local.birthday_date.split("T")[0],

      this.$store.dispatch('userManagement/updateUser', this.data_local)
        .then(response => {
          if (response.status) {
            this.showNotify('success', 'Usuarios', 'Datos actualizados correctamente.')
            this.$router.push('/users')
          } else {
            this.showNotify('danger', 'Usuarios', response.msg)
          }
        })
        .catch(error => this.showNotify('danger', 'Error', error))

    },
    reset_data() {
      // this.data_local = JSON.parse(JSON.stringify(this.data))
      this.$router.push("/users")
    },
    update_avatar() {
      // You can update avatar Here
      // For reference you can check dataList example
      // We haven't integrated it here, because data isn't saved in DB
    },
    updateProfileType(event) {
      this.data_local.profile_type = event.value
    },
    // updateCardType(event) {
    //   this.data_local.card_type_id = event.value
    // },
    isDate(val) {
      return val instanceof Date && !isNaN(val.valueOf());
    },
    showNotify(type,title,text) {
      this.$vs.notify({
        color: type || 'success',
        title: title || 'Correcto!',
        text: text || '',
        time: 8000,
        position: 'top-center',
      })
    },
  },
}
</script>
